<script setup>
import AppLayout from "@/Layouts/AppLayout.vue";
import { reactive } from "vue";
import Modal from "@/Components/Modal.vue";
import Button from "@/Components/Button.vue";
import { Head, Link } from "@inertiajs/vue3";

const props = defineProps({
    reg_open: Array,
    upcoming_events: Array,
    past_events: Array,
});

const myImageModalProps = reactive({
    modalImageSrc: "",
    showModal: false,
});

function showImageModal(src) {
    myImageModalProps.modalImageSrc = src;
    myImageModalProps.showModal = true;
}

function closeImageModal() {
    myImageModalProps.showModal = false;
}

// setTimeout(() => {
//     throw "myError";
// }, 3000);
</script>

<template>
    <AppLayout title="Organize and navigate your rallies and roadbooks with ease">
        <Head>
            <link
                rel="canonical"
                :href="route('landing')"
            />
        </Head>
        <div class="py-8 text-center">
            <h1 class="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
                <span
                    class="block xl:inline"
                    style="color: #1a56db"
                    >RoadbookRally.com</span
                >
            </h1>
            <h3 class="pt-1 text-sm uppercase sm:pt-2 sm:text-xl">Navigation events - Roadbooks - GPX</h3>
        </div>

        <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div class="flex flex-col justify-between">
                <div class="mb-10">
                    <h2
                        class="mx-auto mt-3 max-w-md text-center text-base text-gray-500 sm:text-lg md:mt-5 md:max-w-3xl md:text-xl"
                    >
                        Your one-stop solution for <br />organizing and managing navigation events of any size
                    </h2>
                    <div class="mx-auto mt-5 max-w-md px-12 sm:flex sm:justify-center md:mt-3">
                        <div class="rounded-md shadow">
                            <Button
                                href="/my-events"
                                color="primary"
                                variant="solid"
                                class="flex w-full items-center justify-center rounded-md border border-transparent px-8 py-3 text-base md:px-10 md:py-4 md:text-lg"
                            >
                                Organize Events
                            </Button>
                        </div>
                    </div>
                </div>
                <div>
                    <h2
                        class="mx-auto mt-3 max-w-md text-center text-base text-gray-500 sm:text-lg md:mt-5 md:max-w-3xl md:text-xl"
                    >
                        Manage & share your tracks for free,<br />
                        <span class="text-green-500">or buy and sell them easily</span><br />
                        <span class="text-base"> (Roadbooks & GPX trails)</span>
                    </h2>
                    <div class="mx-auto mt-5 max-w-md px-12 sm:flex sm:justify-center md:mt-3">
                        <div class="rounded-md shadow">
                            <Button
                                href="/faq/how-to-share-tracks-2"
                                color="primary"
                                variant="solid"
                                class="flex w-full items-center justify-center rounded-md border border-transparent px-8 py-3 text-base md:px-10 md:py-4 md:text-lg"
                            >
                                Share Tracks
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="relative py-2 pb-12 pt-20">
            <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div class="mx-3 overflow-hidden bg-white shadow-xl sm:rounded-lg">
                    <div class="mx-8 flex flex-col justify-items-center space-y-4 pt-8 sm:hidden">
                        <div>
                            <a
                                href="/event/2PR7G/alakonnu-ralli-2023/replay#ccs=6380,6394,6430,6342,6428,6412,6368,6414,6386,6438,6366,6390,6408,6418,6459,6444,6450,6314,6354,6370,6340,6372,6382,6384,6436|6438"
                            >
                                <video
                                    src="/img/landing-replay-preview-video.webm"
                                    muted=""
                                    autoplay=""
                                    loop=""
                                    loading="lazy"
                                    class="rounded border bg-white p-1 shadow-lg"
                                ></video>
                            </a>
                        </div>
                        <div class="pb-6 text-center text-gray-800">
                            <a
                                href="/event/2PR7G/alakonnu-ralli-2023/replay#ccs=6380,6394,6430,6342,6428,6412,6368,6414,6386,6438,6366,6390,6408,6418,6459,6444,6450,6314,6354,6370,6340,6372,6382,6384,6436|6438"
                                >Event result synced replay
                            </a>
                        </div>
                        <div>
                            <a href="/event/2PR7G/alakonnu-ralli-2023/replay#ccs=|6319">
                                <img
                                    src="/img/landing/detailed-penalties-report.png"
                                    alt="Detailed penalties report on RoadbookRally.com"
                                    class="rounded border bg-white p-1 shadow-lg"
                                />
                            </a>
                        </div>
                        <div class="pb-6 text-center text-gray-800">
                            <a href="/event/2PR7G/alakonnu-ralli-2023/replay#ccs=|6319"
                                >Detailed penalties report
                            </a>
                        </div>
                        <div>
                            <img
                                src="/img/landing/event-live-tracking.jpg"
                                alt="RoadbookRally.com Event Live Tracking Preview"
                                class="rounded border bg-white p-1 shadow-lg"
                                @click="showImageModal($event.target.src)"
                            />
                        </div>
                        <div class="pb-6 text-center text-gray-800">
                            <span
                                class="cursor-pointer font-medium text-blue-600 hover:underline"
                                @click="showImageModal('/img/landing/event-live-tracking.jpg')"
                                >Event Live Tracking</span
                            >
                        </div>
                        <div>
                            <img
                                src="/img/landing/track-validator-preview.png"
                                alt="RoadbookRally.com Track owner preview and structure validation"
                                class="rounded border bg-white p-1 shadow-lg"
                                @click="showImageModal($event.target.src)"
                            />
                        </div>
                        <div class="pb-6 text-center text-gray-800">
                            <span
                                class="cursor-pointer font-medium text-blue-600 hover:underline"
                                @click="showImageModal('/img/landing/track-validator-preview.png')"
                                >Track owner preview and structure validation</span
                            >
                        </div>
                        <div>
                            <img
                                src="/img/faq/event-edit-penalties-preview.png?v=2"
                                alt="RoadbookRally.com Event penalties configuration"
                                class="cursor-pointer rounded border bg-white p-1 shadow-lg"
                                @click="showImageModal('/img/faq/event-edit-penalties.png?v=2')"
                            />
                        </div>
                        <div class="pb-6 text-center text-gray-800">
                            <span
                                class="cursor-pointer font-medium text-blue-600 hover:underline"
                                @click="showImageModal('/img/faq/event-edit-penalties.png?v=2')"
                                >Event penalties configuration</span
                            >
                        </div>
                    </div>
                    <div class="text-md p-8 leading-7">
                        <div
                            class="float-right mb-6 ml-3 hidden w-2/5 grid-cols-1 justify-items-center space-y-4 sm:grid md:ml-6"
                        >
                            <div>
                                <a
                                    href="/event/2PR7G/alakonnu-ralli-2023/replay#ccs=6380,6394,6430,6342,6428,6412,6368,6414,6386,6438,6366,6390,6408,6418,6459,6444,6450,6314,6354,6370,6340,6372,6382,6384,6436|6438"
                                >
                                    <video
                                        src="/img/landing-replay-preview-video.webm"
                                        muted=""
                                        autoplay=""
                                        loop=""
                                        loading="lazy"
                                        class="rounded border bg-white p-1 shadow-lg"
                                    ></video>
                                </a>
                            </div>
                            <div class="pb-6 text-center text-gray-800">
                                <a
                                    href="/event/2PR7G/alakonnu-ralli-2023/replay#ccs=6380,6394,6430,6342,6428,6412,6368,6414,6386,6438,6366,6390,6408,6418,6459,6444,6450,6314,6354,6370,6340,6372,6382,6384,6436|6438"
                                    >Event result synced replay
                                </a>
                            </div>
                            <div>
                                <a href="/event/2PR7G/alakonnu-ralli-2023/replay#ccs=|6319">
                                    <img
                                        src="/img/landing/detailed-penalties-report.png"
                                        alt="Detailed penalties report on RoadbookRally.com"
                                        class="rounded border bg-white p-1 shadow-lg"
                                    />
                                </a>
                            </div>
                            <div class="pb-6 text-center text-gray-800">
                                <a href="/event/2PR7G/alakonnu-ralli-2023/replay#ccs=|6319"
                                    >Detailed penalties report
                                </a>
                            </div>
                            <div>
                                <a href="/img/landing/event-live-tracking.jpg">
                                    <img
                                        src="/img/landing/event-live-tracking.jpg"
                                        alt="RoadbookRally.com Event Live Tracking Preview"
                                        class="rounded border bg-white p-1 shadow-lg"
                                    />
                                </a>
                            </div>
                            <div class="pb-6 text-center text-gray-800">
                                <a href="/img/landing/event-live-tracking.jpg">Event Live Tracking</a>
                            </div>
                            <div>
                                <img
                                    src="/img/landing/track-validator-preview.png"
                                    alt="RoadbookRally.com Track owner preview and structure validation"
                                    class="cursor-pointer rounded border bg-white p-1 shadow-lg"
                                    @click="showImageModal($event.target.src)"
                                />
                            </div>
                            <div class="pb-6 text-center text-gray-800">
                                <span
                                    class="cursor-pointer font-medium text-blue-600 hover:underline"
                                    @click="showImageModal('/img/landing/track-validator-preview.png')"
                                    >Track owner preview and structure validation</span
                                >
                            </div>
                            <div>
                                <img
                                    src="/img/faq/event-edit-penalties-preview.png?v=2"
                                    alt="RoadbookRally.com Event penalties configuration"
                                    class="cursor-pointer rounded border bg-white p-1 shadow-lg"
                                    @click="showImageModal('/img/faq/event-edit-penalties.png?v=2')"
                                />
                            </div>
                            <div class="pb-6 text-center text-gray-800">
                                <span
                                    class="cursor-pointer font-medium text-blue-600 hover:underline"
                                    @click="showImageModal('/img/faq/event-edit-penalties.png?v=2')"
                                    >Event penalties configuration</span
                                >
                            </div>
                        </div>
                        <h3>
                            RoadbookRally.com makes it easy to organize navigation rally events of any size and
                            type (professional, amateur, practice, friend groups, etc.)
                        </h3>
                        <br />
                        Our event management tools enables organizers to handle every aspect of their event.
                        <br />
                        Providing features such as event creation/management, track (roadbook & GPX trail)
                        access scheduling, competitor registration, online tracking (using our app), real-time
                        results, penalty system, ride replay, historical data and more.
                        <br />
                        <br />
                        The platform also offers a streamlined experience for competitors by delivering digital
                        tracks (roadbooks & GPX trails) exclusively and only during the event. Smooth and
                        intuitive experience.
                    </div>

                    <div class="pl-8 font-bold sm:pt-0">
                        <Link href="/faq/how-do-i-organize-my-first-event-63"
                            >How do I organize my first event?
                        </Link>
                    </div>

                    <div class="p-4">
                        <h4 class="py-4 pl-4 font-bold">Platform Features:</h4>
                        <ul class="text-md ml-8 list-disc space-y-4">
                            <li>
                                Exclusive Track Access: Share your
                                <strong>roadbooks and GPX trails</strong> securely, controlling who gets access
                                and when.
                            </li>
                            <li>"Rolling start" support. No need to hire a start referee.</li>
                            <li>
                                Real-Time Tracking & Results: Keep everyone updated with live event progress and
                                instant results and penalties
                            </li>
                            <li>
                                No special GPS tracking devices are needed; tracking is done using participants'
                                Android devices.
                            </li>
                            <li>
                                The track file is used to automatically identify speed limit zones, which are
                                then penalized based on your event penalties configuration
                            </li>
                            <li>Track (roadbook & GPX trail) structure validation</li>
                            <li>
                                Competitors can register on your Event page, you manage their statuses, section
                                starting times, track access times, payment statuses, bonif., penalties, etc.
                            </li>
                            <li>
                                Neutralization zones, such as those used for refueling during a Selective
                                Section, are automatically detected from the roadbook file
                            </li>
                            <li>
                                Supports
                                <!--multi-stage,-->
                                multi-class, multi-section (including prologue) events
                            </li>
                            <li>Allows for late updates to the tracks even while the event is running</li>
                            <li>Option to import from Rally Navigator ("OpenRally GPX" format)</li>
                            <li>
                                Recording riders' routes, viewing past rides, and comparing results with
                                replayed synchronized starting times
                            </li>
                            <li>Offline support in the app (data sync when a connection becomes available)</li>
                            <li>And more features are coming!</li>
                            <li>
                                <Link href="/faq">Frequently Asked Questions</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="relative py-2 pt-20">
            <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div class="mx-3 overflow-hidden bg-white shadow-xl sm:rounded-lg">
                    <div class="p-8 leading-7">
                        <h3 class="pb-4 font-bold">
                            <img
                                src="/img/rbr-app-icon.png"
                                alt="Download on Google Play store"
                                class="inline h-11 pr-2.5"
                            />
                            RBR Roadbook Reader
                        </h3>
                        <div class="float-right ml-8 w-1/4">
                            <img
                                src="/img/manual/rb-portrait.png"
                                alt="RBR Roadbook Reader - Application"
                                class="mb-8 rounded border bg-white p-1 shadow-lg"
                                @click="showImageModal($event.target.src)"
                            />
                            <img
                                src="/img/help/navigate-by-gpx-trail-screenshot.jpg"
                                alt="RBR Roadbook Reader - Application"
                                class="rounded border bg-white p-1 shadow-lg"
                                @click="showImageModal($event.target.src)"
                            />
                        </div>

                        <h4>Digital roadbook and GPX trail navigation app</h4>
                        <br />
                        The ultimate navigation tool for riders and event organizers. Navigate with ease using
                        all the necessary instruments, speed zones, waypoints, direction arrows, and more. The
                        app's intelligent scrolling feature makes navigation effortless and the app is
                        customizable to each user's preferences. Compatible with any Bluetooth remote in the
                        market, this app is a must-have for any navigation rally event.
                        <br />
                        Experience seamless navigation and never miss a turn again with the RBR Roadbook Reader
                        App!
                    </div>

                    <div class="p-8 pt-2">
                        <h4 class="pb-4 font-bold">App Features:</h4>

                        <ul class="ml-4 list-disc space-y-4">
                            <li>Free</li>
                            <li>Waypoint and speed zone aware navigation (+validation support)</li>
                            <li>Compatibility with external input devices and device's own buttons</li>
                            <li>Autoscroll: ride even if you do not have an external input devices</li>
                            <li>One-click roadbook row scrolling</li>
                            <li>Option to choose the number of roadbook rows to display</li>
                            <li>Option to set the "main roadbook row"</li>
                            <li>Odometer with adjustable distance</li>
                            <li>One-click odometer/tripmeter adjustment</li>
                            <li>Speed limit indicator through background color change</li>
                            <li>Zoom feature to view roadbook diagram or notes in full screen mode</li>
                            <li>
                                Real-time roadbook distance box background color changes indicating approaching
                                turns (with "modern/countdown" DTW style in options)
                            </li>
                            <li>Accurate speed and CAP displays</li>
                            <li>Navigate by Roadbook or by GPX trail</li>
                            <li>Customizable to each rider's preferences</li>
                            <li>And more features are coming!</li>
                            <li>
                                <Link href="/faq">Frequently Asked Questions</Link>
                            </li>
                        </ul>

                        <div class="my-6 flex items-center space-x-4">
                            <a
                                href="https://play.google.com/store/apps/details?id=com.roadbookrally.rbrr"
                                target="_blank"
                            >
                                <img
                                    src="/img/get-it-on-google-play.svg"
                                    alt="Download on Google Play store"
                                    class="h-12"
                                />
                            </a>
                            <div class="hidden leading-8 sm:block">
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.roadbookrally.rbrr"
                                    target="_blank"
                                >
                                    <img
                                        src="/img/rbr-app-icon.png"
                                        alt="Download on Google Play store"
                                        class="inline h-11"
                                    />
                                    <span class="hidden pl-2.5 sm:inline">RBR Roadbook Reader</span>
                                </a>
                            </div>
                        </div>
                        <div class="block sm:hidden">
                            <a
                                href="https://play.google.com/store/apps/details?id=com.roadbookrally.rbrr"
                                target="_blank"
                            >
                                <img
                                    src="/img/rbr-app-icon.png"
                                    alt="Download on Google Play store"
                                    class="inline h-11"
                                />
                                <span class="pl-2.5 sm:inline">RBR Roadbook Reader</span>
                            </a>
                        </div>
                        <!--                        <div class="flex items-center space-x-4">
                            <a href="https://testflight.apple.com/join/AqAWJNJT">
                                <img
                                    src="/img/apple-app-store.svg"
                                    alt="Download on Apple App store"
                                    class="h-32"
                                />
                            </a>
                            <div>
                                The iOS app is in development and should only be used for testing and navigating
                                single tracks with PINs. <br />
                                <span class="text-red-500">Do not use iOS app for events</span>; it's not stable
                                yet. For event participation, use Android. <br />
                                Bluetooth buttons are not supported on iOS yet.
                            </div>
                        </div>-->
                        <!--                        Digital roadbook acts similar to the traditional one:
                        you have a roadbook and buttons to scroll the roadbook.
                        You have a trip meter and buttons to control it. Keep
                        them synced and enjoy the ride!-->
                    </div>
                </div>
            </div>
        </div>

        <div class="relative py-2 pt-20">
            <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div class="overflow-hidden bg-white shadow-xl sm:rounded-lg"></div>
            </div>
        </div>
    </AppLayout>

    <Modal
        :show="myImageModalProps.showModal"
        max-width="min"
        @close="closeImageModal"
    >
        <div @click="closeImageModal()">
            <img
                :src="myImageModalProps.modalImageSrc"
                class="max-h-full rounded border bg-white p-1 shadow-lg sm:max-h-screen sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg"
            />
        </div>
    </Modal>
</template>
